<template>
  <v-img
    v-bind="imageSrc"
    :aspect-ratio="aspectRatio"
    :height="height"
    :width="width"
    :alt="alt"
  />
</template>

<script lang="ts">
import { isString } from "lodash";
import type { MediaFile } from "~/layers/admin/modules/media-library/runtime/types";

export default defineComponent({
  name: "ImageField",
  props: {
    image: { type: Object as PropType<MediaFile> },
    aspectRatio: { type: [String, Number], default: 1 },
    height: { type: [String, Number] },
    width: { type: [String, Number] },
    alt: { type: String },
  },
  setup(props) {
    const imageSrc = computed(() => {
      if (props.image?.image) {
        if (isString(props.image.image)) {
          return {
            src: props.image.image,
            alt: props.image.name,
          };
        }

        if (isMediaImage(props.image.image)) {
          return {
            src: props.image.image.medium,
            lazySrc: props.image.image.thumb,
            alt: props.image.name,
          };
        }

        return {
          ...props.image.image,
          alt: props.image.name,
        };
      }
      if (props.image?.src) {
        return {
          src: props.image.src,
          alt: props.image.name,
        };
      }

      return {
        src: "https://placehold.co/100.png",
      };
    });

    return {
      imageSrc,
    };
  },
});
</script>
