import { has, isObject, isString } from "lodash";
import type {
  MediaFile,
  MediaImage,
} from "../modules/media-library/runtime/types";

export const isMediaImage = (value: any): value is MediaImage => {
  return (
    isObject(value) &&
    (has(value, "large") ||
      has(value, "medium") ||
      has(value, "thumb") ||
      has(value, "small") ||
      has(value, "xsmall"))
  );
};

export const getMediaUrl = (media: MediaFile) => {
  if (media.image) {
    if (isString(media.image)) {
      return media.image;
    }

    if (isMediaImage(media.image)) {
      return (
        media.image.large ||
        media.image.medium ||
        media.image.thumb ||
        media.image.small ||
        media.image.xsmall
      );
    }

    return media.image.src || media.image.lazySrc;
  }

  return media.src;
};
